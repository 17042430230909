import React, { useRef, Fragment, useContext } from "react"
import { navigate } from "gatsby"

import MobileSearch from "../../Search/MobileSearch"
import AddMedicineModal from "../Order/AddMedicineModal"

import { AppContext } from "../../../context/AppContext"
import { isMedicineAlreadyInCart } from "../services/cart"
import { useEpharmacyProducts } from "../hooks/useEpharmacyProducts"

const SearchMedsMobile = ({ locationState }) => {
  const { state, dispatch } = useContext(AppContext)
  const inputRef = useRef({ current: { value: "" } })
  const data = useEpharmacyProducts()

  const handleOnSelect = (medicine) => {
    if (isMedicineAlreadyInCart(medicine, state.epharmacy.medicines))
      dispatch({
        type: "SHOW_TOAST",
        payload: {
          message: `${medicine.productTitle} already in cart`,
          color: "danger",
        },
      })
    else
      dispatch({
        type: "SHOW_MODAL",
        payload: {
          heading: "Add medicine to cart",
          isCard: true,
          headerClass: `has-text-primary has-background-light is-size-5`,
          content: (
            <AddMedicineModal
              addToCart={addMedicineToCart}
              medicine={medicine}
              isSpecialOrder
            />
          ),
        },
      })
  }

  const searchComponentProps = {
    placeholder: "Paracetamol",
    label: "Medicine Search",
    dataSource: {
      type: "graphql",
      data: data.allProducts.nodes,
      lookupKeys: ["molecule", "brand"],
    },
    onSelect: handleOnSelect,
    inputRef: inputRef,
    backRoute: "/special-order/input-patient",
    locationState,
  }

  const addMedicineToCart = async (item, qty, discountValues) => {
    const medicineList = [...state.epharmacy.medicines]
    medicineList.push({ ...item, qty: qty, discountValues })
    await dispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...state,
        epharmacy: { ...state.epharmacy, medicines: medicineList },
      },
    })
    dispatch({
      type: "SHOW_TOAST",
      payload: {
        message: `Added ${item.molecule} to cart`,
        color: "success",
      },
    })
    navigate("/special-order/input-patient", {
      state: {
        ...locationState,
      },
    })
  }

  return (
    <Fragment>
      <MobileSearch {...searchComponentProps} />
    </Fragment>
  )
}

export default SearchMedsMobile
